export default class MailSender {
    constructor(successHandler, failureHandler) {
        this.successHandler = successHandler;
        this.failureHandler = failureHandler;
        this.mailEndpoint = process.env.REACT_APP_BASE_HOST_URL + "/mail";
    }

    sendMail(mailObject) {
        fetch(this.mailEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(mailObject)
            })
            .then(response => {
                if (!response.ok) {
                    this.failureHandler();
                } else {
                    this.successHandler();
                }
            })
            .catch(function (error) {
                this.failureHandler();
                console.log(error);
            });
    }
}