Array.prototype.pushUnique = function(item) {
    if (this.indexOf(item) === -1) {
        this.push(item);
        return true;
    }
    return false;
}

Array.prototype.popIfExists = function() {
    var what, a = arguments, L = a.length, ax;
    while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
        }
    }
    return this;
};

export default class ProjectFilterAndSortHelper {
    constructor() {
        this.statusFilters = [];
        this.labelFilters = [];
        this.sortString = "";
    }

    filterButtonClickedHandler(event, isChecked) {
        let filterCheckboxId = event.target.id;

        let pushOrPop = (array, value) => {
            if (isChecked) {
                array.pushUnique(value);
            } else {
                array.popIfExists(array, value);
            }
        }

        if (filterCheckboxId.startsWith("projectLabelFilter-")) {
            let labelFilterId = filterCheckboxId.replace("projectLabelFilter-", "");
            pushOrPop(this.labelFilters, labelFilterId);
        } else {
            switch(filterCheckboxId) {
                case "projectStatusPendingFilter":
                    pushOrPop(this.statusFilters, "PENDING");
                    break;
                case "projectStatusAcceptedFilter":
                    pushOrPop(this.statusFilters, "APPROVED");
                    break;
                case "projectStatusRejectedFilter":
                    pushOrPop(this.statusFilters, "DISAPPROVED");
                    break;
                case "projectStatusCancelledFilter":
                    pushOrPop(this.statusFilters, "CANCELLED");
                    break;
                default:
                    pushOrPop(this.statusFilters, "PENDING");
                    break;
            }
        }
    }

    sortButtonClickedHandler(event) {
        switch(event.target.id) {
            case "projectSortTitleAsc":
                this.sortString = "title;asc";
                break;
            case "projectSortTitleDesc":
                this.sortString = "title;desc";
                break;
            case "projectSortDateAsc":
                this.sortString = "createdAt;asc";
                break;
            case "projectSortDateDesc":
                this.sortString = "createdAt;desc";
                break;
            case "projectSortStatusAsc":
                this.sortString = "projectStatus;asc";
                break;
            case "projectSortStatusDesc":
                this.sortString = "projectStatus;desc";
                break;
            default:
                this.sortString = "title;asc";
                break;
        }
    }

    prepareFilterAndSortString() {
        let filterString = this.prepareFilterString();
        let prefix = (filterString === "" && this.sortString === "") ? "" : "?";
        let connector = filterString === "" ? "" : "&";
        let filter = filterString === "" ? "" : ("filterBy=" + filterString);
        let sort = this.sortString === "" ? "" : ("sortBy=" + this.sortString);
        return prefix + filter + connector + sort;
    }

    prepareFilterString() {
        let result = "";
        let connector = (this.statusFilters.length === 0 || this.labelFilters === 0) ? "" : ";";
        if (this.statusFilters.length > 0) {
            result += "projectStatus:" + this.statusFilters.join(",");
        }

        result += connector;
        if (this.labelFilters.length > 0) {
            result += "labelId:" + this.labelFilters.join(",");
        }
        return result;
    }
}
