import React from "react";

import { Badge, Card, CardBody, Button, CardFooter } from "reactstrap";

export default class ProjectShortList extends React.Component {
    learnMoreButtonClickHandler(project) {
        this.props.learnMoreButtonHandler(project);
    }

    cardClickHandler(projectId) {
        this.props.onProjectListCardClickHander(projectId);
    }

    prepareStateBadge(status) {
        switch (status) {
            case "PENDING":
                return (<Badge color="info" pill className="mr-1">Oczekujący</Badge>)
            case "APPROVED":
                return (<Badge color="success" pill className="mr-1">Zatwierdzony</Badge>)
            case "DISAPPROVED":
                return (<Badge color="danger" pill className="mr-1">Odrzucony</Badge>)
            case "CANCELLED":
                return (<Badge color="warning" pill className="mr-1">Anulowany</Badge>)
        }
    }

    prepareProjectLabelBadges(projectLabels) {
        return projectLabels.map(projectLabel =>(
            <Badge key={projectLabel.id} color="primary" pill className="mr-1">
                {projectLabel.title}
            </Badge>
        ))
    }

    render() {
        return (
            this.props.projects.map(project => (
                <Card key={project.id} className="shadow-sm shadow--hover">
                    <a style={{ cursor: 'pointer' }} onClick={this.cardClickHandler.bind(this, project.id)}>
                        <CardBody>
                            <div className="d-flex">
                                <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                                    <img width={42} className="rounded-circle" src={project.thumbnailUrlToShow}/>
                                </div>
                                <div className="pl-3">
                                    <h5 className="title">
                                        {project.title}
                                    </h5>
                                </div>
                            </div>
                            <p className="description mt-1">
                                {project.excerpt}
                            </p>

                            
                        </CardBody>
                    </a>
                    <CardFooter>
                        <a className="text-primary"
                            href="#pablo"
                            onClick={this.learnMoreButtonClickHandler.bind(this, project)}>
                                <div className="d-flex align-items-center">
                                    <Badge className="badge-circle mr-2"
                                        color="dark">
                                        <i className="ni ni-single-copy-04" />
                                    </Badge>
                                    {this.prepareStateBadge(project.status)}
                                    {this.prepareProjectLabelBadges(project.labels)}
                                </div>
                        </a>
                    </CardFooter>
                </Card>
            ))
        );
    }
}