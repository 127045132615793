/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import {toast} from 'react-toastify';
import {Map, TileLayer, GeoJSON} from 'react-leaflet';
import ReactDOMServer from "react-dom/server";
import 'react-toastify/dist/ReactToastify.css';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  CardHeader
} from "reactstrap";

// core components
import MainNavbar from "components/Navbars/MainNavbar/index.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import SortDropdown from "components/Dropdowns/SortDropdown/index.js";
import LabelFilterDropdown from "components/Dropdowns/LabelFilterDropdown/index.js";
import ProjectShortList from "components/Lists/ProjectShortList/"
import ProjectList from "components/Lists/ProjectList/"
import ProjectInfoModal from "components/Modals/ProjectInfoModal/"

// web services
import ProjectFetcher from 'WebService/ProjectFetcher';
import ProjectLabelFetcher from 'WebService/ProjectLabelFetcher';
import SettingsFetcher from 'WebService/SettingsFetcher';
import MailSender from 'WebService/MailSender';

// utils
import ProjectFilterAndSortHelper from 'Utils/ProjectFilterAndSortHelper/'


class Landing extends React.Component {
    mapLayers = []
    mapKey = 0
    state = {
        plainTabs: 1,
        isSortDropdownOpen: false,
        isFilterDropdownOpen: false,
        projects: [],
        mapLayers: [],
        projectLabels: [],
        modalProject: {},
        mailMessage: {
          title: "",
          authorMail: "",
          message: ""
        },
        areSettingsFetched: false,
        filterButtonsState: {
          projectStatusPendingFilter: false,
          projectStatusAcceptedFilter: false,
          projectStatusRejectedFilter: false,
          projectStatusCancelledFilter: false
        },
        sortButtonsState: {
            isTitleAscDropdownItemActive: true,
            isTitleDescDropdownItemActive: false,
            isDateAscDropdownItemActive: false,
            isDateDescDropdownItemActive: false,
            isStatusAscDropdownItemActive: false,
            isStatusDescDropdownItemActive: false
        },
        isProjectInfoModalOpen: false,
        keyMAP: Math.random()
    };

  constructor(props) {
    super(props);
    // I. STAGE - SETTINGS
    this.settingsFetcher = new SettingsFetcher(() => {
      this.projectLabelFetcher.fetchAllProjectLabels();
      this.setSettingsFetched(true);
    }, () => {
        toast.error("Wystąpił błąd pobierania ustawień portalu.");
    });
    // II. STAGE - PROJECT LABELS
    this.projectLabelFetcher = new ProjectLabelFetcher((projectLabels) => { 
      this.setProjectLabels(projectLabels);
      this.projectFetcher.fetchAllProjects();
    }, () => {
        toast.error("Błąd pobierania etykiet.");
    });
    // III. STAGE - PROJECTS
    this.projectFetcher = new ProjectFetcher((projects) => { 
      this.updateProjectsWithLabels(projects);
    }, () => {
      toast.error("Błąd pobierania projektów.");
    });
    this.mailSender = new MailSender(() => {
      toast("Twoja wiadomość została wysłana.");
      this.resetMailFormValues();
    }, () => {
      toast.error("Wystąpił błąd podczas wysyłania wiadomości.");
    });
    this.filterAndSortHelper = new ProjectFilterAndSortHelper();
    this.getKey = this.getKey.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.handleStateFilterChange = this.handleStateFilterChange.bind(this);
    this.handleMailMessageInputChange = this.handleMailMessageInputChange.bind(this);
    this.settingsFetcher.fetchSettings();
  }

  componentWillMount() {
    toast.configure();
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  // -- STATE OPERATIONS --

  getKey(){
    return this.mapKey++;
  }

  setProjects(projects) {
    this.setState({
        projects: projects
    });
  }

  setSettingsFetched(areFetched) {
    this.setState({
      areSettingsFetched: areFetched
    })
  }

  setProjectLabels(projectLabels) {
    this.setState({
      projectLabels: projectLabels
    });
  }

  appendMapLayers(newMapLayerValue) {
    this.setState(previousState => ({
        mapLayers: [...previousState.mapLayers, newMapLayerValue]
    }));
  }

  openInfoModal = (project) => {
    this.setState({
        modalProject: project,
        isProjectInfoModalOpen: true
    });
  }

  closeInfoModal = () => {
    this.setState({
      modalProject: {},
      isProjectInfoModalOpen: false
    });
  }

  updateFilterButtonState(buttonId) {
    let filterButtonsStates = this.state.filterButtonsState;
    filterButtonsStates[buttonId] = !filterButtonsStates[buttonId];
    this.setState({
        filterButtonsState: filterButtonsStates
    });
  }

  // -- ACTION TOGGLES --
  
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  toggleSortDropdown = () => {
    this.setState({
      isSortDropdownOpen: !this.state.isSortDropdownOpen
    })
  }

  toggleFilterDropdown = () => {
    this.setState({
      isFilterDropdownOpen: !this.state.isFilterDropdownOpen
    })
  }

  onProjectShortListClickedHandler = (projectId) => {
    let properLayer = this.state.mapLayers.find(layer => {
        return layer.projectId === projectId;
    });

    if (properLayer !== undefined) {
      properLayer.layer.openPopup();
    } else {
      toast.info("Dla tego projektu nie ma punktu na mapie.", {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  updateProjectsWithLabels(projects) {
    projects.forEach(project => {
      project.labels = this.projectLabelFetcher.joinWithLabels(this.state.projectLabels, project.labelIds);
    });
    
    this.prepareProjectThumbnailUrl(projects);
    this.setProjects(projects);
  } 

  prepareProjectThumbnailUrl(projects) {
    projects.forEach(project => {
        project.thumbnailUrlToShow = project.thumbnailUrl;
        
        if ((project.thumbnailUrl === undefined || project.thumbnailUrl === "") 
            && (project.labels !== undefined && project.labels.length > 0)) {
            project.thumbnailUrlToShow = project.labels[0].thumbnailUrl;
        }
    });
  }

  handleStateFilterChange(event) {
    this.updateFilterButtonState(event.target.id);

    this.filterAndSortHelper.filterButtonClickedHandler(event, this.state.filterButtonsState[event.target.id]);
    this.projectFetcher.fetchAllProjects(this.filterAndSortHelper.prepareFilterAndSortString());
  }

  activeClassForFilterButton(buttonId) {
    let filterButtonsState = this.state.filterButtonsState;
    return filterButtonsState[buttonId] === true ? "active" : "";
  }

  sortButtonClickedHandler(event, newSortButtonsState) {
    this.filterAndSortHelper.sortButtonClickedHandler(event);
    this.projectFetcher.fetchAllProjects(this.filterAndSortHelper.prepareFilterAndSortString());
    this.setState({
      sortButtonsState: newSortButtonsState
    });
  }

  resetMailFormValues() {
    this.setState({
      mailMessage: {
        title: "",
        authorMail: "",
        message: ""
      }
    });
  }

  sendMail() {
    this.mailSender.sendMail(this.state.mailMessage);
  }

  handleMailMessageInputChange(event) {
    const target = event.target;
    const name = target.name; 
    const value = target.value;

    var mailMessage = this.state.mailMessage;
    mailMessage[name] = value;

    this.setState({
      mailMessage: mailMessage
    });
  }

  // -- MAP OPERATIONS --

  onEachFeature(_, layer, project) {
    const ProjectMapPopup = () => {
      return (
        <div className="d-flex">
            <div className="icon icon-shape icon-shape-primary rounded-circle">
                <img width={42} className="rounded-circle" src={project.thumbnailUrlToShow}/>
            </div>
            <div className="pl-3">
                <h6 className="title">{project.title}</h6>
            </div>
        </div>
      );
    };

    layer.bindPopup(ReactDOMServer.renderToString(<ProjectMapPopup/>));
    this.appendMapLayers({
      "projectId": project.id,
      "layer": layer
    });
  } 

  // -- JSX --

  render() {
    return (
      <>
        <MainNavbar />

        <ProjectInfoModal 
            isOpen={this.state.isProjectInfoModalOpen}
            modalProject={this.state.modalProject}
            closeInfoModal={this.closeInfoModal} />

        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6">
                      <h1 className="display-3 text-white">
                        Projekty Budżetu Obywatelskiego 
                        <span>dla gminy Kościerzyna</span>
                      </h1>
                      <p className="lead text-white">
                        Interaktywne narzędzie do przeglądu propozycji
                        projektów korzystających z budżetu obywatelskiego
                      </p>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
                  <polygon className="fill-white" points="2560 0 2560 100 0 100" />
                </svg>
              </div>
            </section>
          </div>

          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col md="12">
                  <Card className="shadow border-0">
                    
                    <CardHeader>
                      <Nav className="flex-column flex-md-row" id="tabs-icons-text" pills role="tablist">
                        <NavItem>
                          <NavLink
                            aria-selected={this.state.iconTabs === 1}
                            className={classnames("mb-sm-3 mb-md-0", {
                              active: this.state.plainTabs === 1
                            })}
                            onClick={e => this.toggleNavs(e, "plainTabs", 1)}
                            href="#pablo"
                            role="tab">
                            Mapa
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-selected={this.state.iconTabs === 2}
                            className={classnames("mb-sm-3 mb-md-0", {
                              active: this.state.plainTabs === 2
                            })}
                            onClick={e => this.toggleNavs(e, "plainTabs", 2)}
                            href="#pablo"
                            role="tab">
                            Lista
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </CardHeader>

                    <CardHeader>
                      <div className="float-left">
                        <SortDropdown 
                          toggle={this.toggleSortDropdown}
                          isOpen={this.state.isSortDropdownOpen}
                          sortButtonsState={this.state.sortButtonsState}
                          sortButtonClickedHandler={(e, n) => this.sortButtonClickedHandler(e, n)} />

                        <LabelFilterDropdown
                          toggle={this.toggleFilterDropdown}
                          isOpen={this.state.isFilterDropdownOpen}
                          filterButtonsState={this.state.filterButtonsState}
                          projectLabels={this.state.projectLabels}
                          handleLabelFilterChange={this.handleStateFilterChange}/>
                      </div>

                      <div className="float-right">
                        <Button id="projectStatusPendingFilter" onClick={e => this.handleStateFilterChange(e)} className={"btn-1 ml-1 " + this.activeClassForFilterButton("projectStatusPendingFilter")} color="info" outline type="button" size="sm">Oczekujące</Button>
                        <Button id="projectStatusAcceptedFilter" onClick={e => this.handleStateFilterChange(e)} className={"btn-1 ml-1 " + this.activeClassForFilterButton("projectStatusAcceptedFilter")} color="success" outline type="button" size="sm">Zatwierdzone</Button>
                        <Button id="projectStatusCancelledFilter" onClick={e => this.handleStateFilterChange(e)} className={"btn-1 ml-1 " + this.activeClassForFilterButton("projectStatusCancelledFilter")} color="warning" outline type="button" size="sm">Anulowane</Button>
                        <Button id="projectStatusRejectedFilter" onClick={e => this.handleStateFilterChange(e)} className={"btn-1 ml-1 " + this.activeClassForFilterButton("projectStatusRejectedFilter")} color="danger" outline type="button" size="sm">Odrzucone</Button>
                      </div>
                    </CardHeader>

                    <CardBody>
                      <TabContent activeTab={"plainTabs" + this.state.plainTabs}>
                        <TabPane tabId="plainTabs1" >
                          <Row>
                            <Col style={{maxHeight: '750px', overflowY: 'auto'}} className="order-md-1" md="5">

                                <ProjectShortList 
                                    projects={this.state.projects}
                                    onProjectListCardClickHander={this.onProjectShortListClickedHandler}
                                    learnMoreButtonHandler={this.openInfoModal}/>

                            </Col>
                            <Col className="order-md-2" md="7">
                              { this.state.areSettingsFetched &&
                                <Map key={this.getKey} id='leafletmap' style={{height: '750px'}} center={[localStorage.getItem('default_map_latitude_setting'), localStorage.getItem('default_map_longitude_setting')]} zoom={localStorage.getItem('default_map_zoom')}>
                                  <TileLayer 
                                    url='https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiaW1wZXJpYWxhcnQiLCJhIjoiY2sycnZhbnBsMGMxaDNudGpmcW92emY4ZiJ9.GBkAQgsJfdYUgx2soC7PWQ' 
                                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                                    id="mapbox/streets-v11" />
                                    {this.state.projects.map(project => {
                                      return (<GeoJSON onEachFeature={(_, layer) => this.onEachFeature(_, layer, project)} key={project.id} data={JSON.parse(JSON.parse(decodeURIComponent(project.geoJsonData)))} />)
                                    })};
                                </Map>
                              }
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="plainTabs2" style={{maxHeight: '750px', overflowY: 'auto'}}>
                            <ProjectList projects={this.state.projects}/>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section section-lg pt-0">
            <Container>
              <Card className="bg-gradient-warning shadow-lg border-0">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="8">
                      <h3 className="text-white">
                        Zgłoś swój projekt do kolejnej edycji głosowania nad Budżetem Obywatelskim!
                      </h3>
                      <p className="lead text-white mt-3">
                        Dodając swój projekt wspierasz okolicę i masz realny wpływ na kształt Twojego otoczenia. Nie czekaj i już dziś zgłoś swój projekt!
                      </p>
                    </Col>
                    <Col className="ml-lg-auto" lg="3">
                      <Button
                        block
                        className="btn-white"
                        color="default"
                        href="http://koscierzyna.gda.pl/portal?id=2232470"
                        size="lg"
                        taget="_blank"
                      >
                        Więcej informacji
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>
          </section>
          
          <section className="section section-lg bg-gradient-default">
            <Container className="pt-lg pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">Czym jest budżet obywatelski?</h2>
                  <p className="lead text-white">
                    Budżet obywatelski to o proces w którym mieszkańcy decydują o przeznaczeniu części lub całości budżetu danego miasta lub gminy. Decyzje mogą być podejmowane bezpośrednio przez mieszkańców bądź za pośrednictwem organizacji społecznych. Najważniejszym elementem budżetu obywatelskiego są regularne spotkania mieszkańców z przedstawicielami władz, podczas których rozmawia się o przeznaczeniu wspólnych pieniędzy
                  </p>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          
          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Masz pytania, uwagi?</h4>
                      <p className="mt-0">
                        Skorzystaj z formularza kontaktowego.
                      </p>
                      <FormGroup
                        className={classnames("mt-5", {
                          focused: this.state.nameFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-user-run" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                          value={this.state.mailMessage.title}
                            onChange={this.handleMailMessageInputChange}
                            placeholder="Temat Twojej wiadomości"
                            name="title"
                            type="text"
                            onFocus={() => this.setState({ nameFocused: true })}
                            onBlur={() => this.setState({ nameFocused: false })}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.emailFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                          value={this.state.mailMessage.authorMail}
                            onChange={this.handleMailMessageInputChange}
                            name="authorMail"
                            placeholder="Twój adres e-mail"
                            type="email"
                            onFocus={() => this.setState({ emailFocused: true })}
                            onBlur={() => this.setState({ emailFocused: false })}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Input
                          value={this.state.mailMessage.message}
                          onChange={this.handleMailMessageInputChange}
                          name="message"
                          className="form-control-alternative"
                          cols="80"
                          name="message"
                          placeholder="Twoja wiadomość..."
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                      <div>
                        <Button
                          block
                          onClick={this.sendMail}
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                        >
                          Wyślij wiadomość
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>

        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Landing;
