export default class ProjectLabelFetcher {
    constructor(successHandler, failureHandler) {
        this.successHandler = successHandler;
        this.failureHandler = failureHandler;
        this.projectLabelsEndpoint = process.env.REACT_APP_BASE_HOST_URL + "/project-labels";
    }

    fetchAllProjectLabels(sortString) {
        sortString = typeof sortString !== 'undefined' ? sortString : "";
        fetch(this.projectLabelsEndpoint + sortString)
            .then(response => response.json())
            .then(response => {
                if (response.content !== undefined) {
                    let labels = this.mapToLabelObjects(response.content);
                    this.successHandler(labels);
                } else {
                    this.successHandler([]);
                }
            })
            .catch(error => {
                console.log(error);
                this.failureHandler();
            });
    }

    mapToLabelObjects(projectLabelResources) {
        return projectLabelResources
            .map(resource => resource.projectLabel)
            .map(label => {
                return {
                    id: label.id,
                    title: label.title,
                    description: label.description,
                    projectCount: label.projectCount,
                    thumbnailUrl: label.thumbnailUrl
                }
            })
    }

    joinWithLabels(labels, selectedLabelIds) {
        return labels
            .filter(label => selectedLabelIds.includes(label.id));
    }
}
