import React from "react";

import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';

export default class SortDropdown extends React.Component {
    handleSortButtonClicked(event) {
        let newSortButtonsState = {
            isTitleAscDropdownItemActive: event.target.id === "projectSortTitleAsc" ? !this.props.isTitleAscDropdownItemActive : this.props.isTitleAscDropdownItemActive,
            isTitleDescDropdownItemActive: event.target.id === "projectSortTitleDesc" ? !this.props.isTitleDescDropdownItemActive : this.props.isTitleDescDropdownItemActive,
            isDateAscDropdownItemActive: event.target.id === "projectSortDateAsc" ? !this.props.isDateAscDropdownItemActive : this.props.isDateAscDropdownItemActive,
            isDateDescDropdownItemActive: event.target.id === "projectSortDateDesc" ? !this.props.isDateDescDropdownItemActive : this.props.isDateDescDropdownItemActive,
            isStatusAscDropdownItemActive: event.target.id === "projectSortStatusAsc" ? !this.props.isStatusAscDropdownItemActive : this.props.isStatusAscDropdownItemActive,
            isStatusDescDropdownItemActive: event.target.id === "projectSortStatusDesc" ? !this.props.isStatusDescDropdownItemActive : this.props.isStatusDescDropdownItemActive
        }

        this.props.sortButtonClickedHandler(event, newSortButtonsState);
    }

    render() {
        return (
            <Dropdown isOpen={this.props.isOpen} toggle={this.props.toggle}>
                <DropdownToggle color="neutral" size="sm" className="mb-sm-3 mb-md-0" caret>Sortuj</DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem onClick={e => this.handleSortButtonClicked(e)} id="projectSortTitleAsc" active={this.props.sortButtonsState.isTitleAscDropdownItemActive}>Według tytułu: rosnąco ↑</DropdownItem>
                    <DropdownItem onClick={e => this.handleSortButtonClicked(e)} id="projectSortTitleDesc" active={this.props.sortButtonsState.isTitleDescDropdownItemActive}>Według tytułu: malejąco ↓</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={e => this.handleSortButtonClicked(e)} id="projectSortDateAsc" active={this.props.sortButtonsState.isDateAscDropdownItemActive}>Według daty: rosnąco ↑</DropdownItem>
                    <DropdownItem onClick={e => this.handleSortButtonClicked(e)} id="projectSortDateDesc" active={this.props.sortButtonsState.isDateDescDropdownItemActive}>Według daty: malejąco ↓</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={e => this.handleSortButtonClicked(e)} id="projectSortStatusAsc" active={this.props.sortButtonsState.isStatusAscDropdownItemActive}>Według statusu: rosnąco ↑</DropdownItem>
                    <DropdownItem onClick={e => this.handleSortButtonClicked(e)} id="projectSortStatusDesc" active={this.props.sortButtonsState.isStatusDescDropdownItemActive}>Według statusu: malejąco ↓</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        )
    }
}