import React from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge } from 'reactstrap';

export default class ProjectInfoModal extends React.Component {
    constructor(props) {
        super(props);
        this.surveyButtonClicked = this.surveyButtonClicked.bind(this);
    }

    extractAttachmentFileName(attachments) {
        return (attachments === undefined || attachments.length === 0) ? ""
            : attachments[0].replace(/^.*[\\\/]/, '');
    }

    prepareProjectLabelBadges(projectLabels) {
        if (projectLabels === undefined) {
            return;
        }
        return projectLabels.map(projectLabel => (
            <Badge key={projectLabel.id} color="primary" pill className="mr-1">
                {projectLabel.title}
            </Badge>
        ))
    }

    prepareStateBadge(status) {
        switch (status) {
            case "PENDING":
                return (<Badge color="info" pill className="mr-1">Oczekujący</Badge>)
            case "APPROVED":
                return (<Badge color="success" pill className="mr-1">Zatwierdzony</Badge>)
            case "DISAPPROVED":
                return (<Badge color="danger" pill className="mr-1">Odrzucony</Badge>)
            case "CANCELLED":
                return (<Badge color="warning" pill className="mr-1">Anulowany</Badge>)
        }
    }

    surveyButtonClicked() {
        window.open(this.props.modalProject.formUrl, '_blank');
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} className="modal-lg">
                <ModalHeader>{this.props.modalProject.title}</ModalHeader>
                <ModalBody>
                    <form className="">
                        <input type="hidden" className="project-geojson-data" value="{{json.geoJsonData}}" />
                        <div className="d-flex position-relative row form-group align-items-center">
                            <h5 className="col-sm-2 card-title">Autor</h5>
                            <div className="col-sm-10">{this.props.modalProject.authorName}</div>
                        </div>
                        <div className="position-relative row form-group">
                            <h5 className="col-sm-2 card-title">Status</h5>
                            <div className="col-sm-10">
                                {this.prepareStateBadge(this.props.modalProject.status)}
                            </div>
                        </div>
                        <div className="position-relative row form-group">
                            <h5 className="col-sm-2 card-title">Opis</h5>
                            <div className="col-sm-10">
                                {this.props.modalProject.description}
                            </div>
                        </div>
                        <div className="position-relative row form-group">
                            <h5 className="col-sm-2 card-title">Etykiety</h5>
                            <div className="col-sm-10">
                                {this.prepareProjectLabelBadges(this.props.modalProject.labels)}
                            </div>
                        </div>
                        <div className="position-relative row form-group align-items-center">
                            <h5 className="col-sm-2 card-title">Rok</h5>
                            <div className="col-sm-10">{this.props.modalProject.year}</div>
                        </div>
                        {this.props.modalProject.formUrl != undefined && this.props.modalProject.formUrl != "" &&
                            <div className="position-relative row form-group align-items-center">
                                <h5 className="col-sm-2 card-title">Ankieta</h5>
                                <Button color="primary" onClick={this.surveyButtonClicked}>Wypełnij ankietę</Button>
                            </div>
                        }
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.props.closeInfoModal}>Zamknij</Button>
                </ModalFooter>
            </Modal>
        )
    }
}