import React from "react";

import { Badge, Card, CardBody  } from "reactstrap";

export default class ProjectList extends React.Component {
    prepareStateBadge(status) {
        switch (status) {
            case "PENDING":
                return (<Badge color="info" pill className="mr-1">Oczekujący</Badge>)
            case "APPROVED":
                return (<Badge color="success" pill className="mr-1">Zatwierdzony</Badge>)
            case "DISAPPROVED":
                return (<Badge color="danger" pill className="mr-1">Odrzucony</Badge>)
            case "CANCELLED":
                return (<Badge color="warning" pill className="mr-1">Anulowany</Badge>)
        }
    }

    prepareProjectLabelBadges(projectLabels) {
        return projectLabels.map(projectLabel =>(
            <Badge key={projectLabel.id} color="primary" pill className="mr-1">
                {projectLabel.title}
            </Badge>
        ))
    }

    prepareYearBadge(year) {
        return(
            <Badge color="dark" pill className="mr-1">
                {year}
            </Badge>
        )
    }

    render() {
        return (
            this.props.projects.map(project => (
                <Card key={project.id} className="shadow-sm shadow--hover">
                  <CardBody>
                    <div className="d-flex align-items-center">
                      <div className="icon icon-shape icon-shape-primary rounded-circle">
                        <img width={42} className="rounded-circle" src={project.thumbnailUrlToShow}/>
                      </div>
                      <div className="pl-3">
                        <h5 className="title">
                            {project.title}
                        </h5>
                      </div>
                    </div>

                    <p className="description mt-3">
                      {project.description}
                    </p>

                    <div className="d-flex mt-3 mb-3 align-items-center">
                        <div>
                            <Badge className="badge-circle mr-3"
                                color="success">
                                <i className="ni ni-ruler-pencil" />
                            </Badge>
                        </div>
                        <div>
                            <h6 className="mb-0 description">
                                {project.authorName}
                            </h6>
                        </div>
                    </div>

                    <div>
                        {this.prepareYearBadge(project.year)}
                        {this.prepareStateBadge(project.status)}
                        {this.prepareProjectLabelBadges(project.labels)}
                    </div>
                  </CardBody>
                </Card>
            ))
        );
    }
}