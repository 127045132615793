import React from "react";

import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Button} from 'reactstrap';

export default class LabelFilterDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.handleFilterButtonClicked = this.handleFilterButtonClicked.bind(this);
    this.shouldFilterButtonBeActive = this.shouldFilterButtonBeActive.bind(this);
  }

  handleFilterButtonClicked(event) {
    this.props.handleLabelFilterChange(event);
  }

  shouldFilterButtonBeActive(id) {
    let filterButtonStates = this.props.filterButtonsState;
    return filterButtonStates[id] === undefined ? false : filterButtonStates[id];
  }

  render() {
    return (
      <Dropdown isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <DropdownToggle color="neutral" size="sm" className="mb-sm-3 mb-md-0" caret>Etykiety</DropdownToggle>
        <DropdownMenu right>
          {this.props.projectLabels.map(projectLabel => (
              <DropdownItem id={"projectLabelFilter-" + projectLabel.id}
                active={this.shouldFilterButtonBeActive("projectLabelFilter-" + projectLabel.id)} 
                onClick={e => this.handleFilterButtonClicked(e)} 
                className="text-center" 
                toggle={false} 
                key={projectLabel.id}>
                {projectLabel.title}
              </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    )
  }
}