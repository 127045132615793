export default class ProjectFetcher {
    constructor(completionHandler, failureHandler) {
        this.successHandler = completionHandler;
        this.failureHandler = failureHandler;
        this.projectEndpoint = process.env.REACT_APP_BASE_HOST_URL + "/projects/list";
    }

    fetchAllProjects(filterAndSort) {
        filterAndSort = typeof filterAndSort !== 'undefined' ? filterAndSort : "";
        fetch(this.projectEndpoint + filterAndSort)
            .then(response => response.json())
            .then(response => {
                if (this.isResponseEmpty(response)) {
                    this.successHandler([]);
                } else {
                    if (response._embedded !== undefined && response._embedded.projectResources ) {
                        let projects = this.mapToProjectObjects(response._embedded.projectResources);
                        this.successHandler(projects);
                    } else {
                        this.successHandler([]);
                    }
                }
            })
            .catch(error => {
                console.log(error);
                this.failureHandler();
            });
    }

    mapToProjectObjects(projectResources) {
        let projects = projectResources
            .map(project => project.project)
            .map(project => {
                var geoJsonData = (project.geoJsonData === "") ? 
                    "" : encodeURIComponent(JSON.stringify(project.geoJsonData));
                return {
                    id: project.projectId,
                    title: project.title,
                    description: project.description,
                    excerpt: this.prepareDescriptionExcerpt(project.description),
                    createdAt: project.createdAt,
                    updatedAt: project.updatedAt,
                    attachmentUrls: project.attachmentUrls,
                    authorName: project.authorName,
                    geoJsonData: geoJsonData,
                    status: project.projectStatus,
                    thumbnailUrl: project.thumbnailUrl,
                    year: project.validityYear,
                    labelIds: project.projectLabelIds,
                    formUrl: project.formUrl
                };
            })

        return projects;
    }

    isResponseEmpty(response) {
        return response === undefined || response._embedded === undefined || response._embedded.projectResources === undefined;
    }

    prepareDescriptionExcerpt(description) {
        if (description.length > 200) {
            return description.substring(0, 100) + "...";
        }
        return description;
    }
}