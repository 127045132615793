export default class SettingsFetcher {
    constructor(completionHandler, failureHandler) {
        this.settingsKeys = [
            "aws_host_setting",
            "default_map_latitude_setting",
            "default_map_longitude_setting",
            "default_map_zoom",
            "map_box_api_key_setting",
            "default_project_author"
        ]
        this.successHandler = completionHandler;
        this.failureHandler = failureHandler;
        this.settingsEndpoint = process.env.REACT_APP_BASE_HOST_URL + "/settings";
    }

    fetchSettings() {
        fetch(this.settingsEndpoint)
            .then(response => response.json())
            .then(response => {
                if (response._embedded !== undefined && response._embedded.settingResources !== undefined) {
                    let rawSettings = response._embedded.settingResources;
                    if (this.areAllKeysPresentInResponse(rawSettings)) {
                        this.updateLocalStorage(rawSettings);
                        this.successHandler(this.extractSettings(rawSettings));
                    } else {
                        this.failureHandler();
                    }
                } else {
                    this.successHandler([]);
                }
            })
            .catch(error => {
                this.failureHandler();
                console.log(error);
            });
    }

    extractSettings(fetchedSettings) {
        return {
            awsHostSetting: this.extractSingleSetting('aws_host_setting', fetchedSettings),
            mapBoxApiKeySetting: this.extractSingleSetting('map_box_api_key_setting', fetchedSettings),
            defaultMapLatitudeSetting: this.extractSingleSetting('default_map_latitude_setting', fetchedSettings),
            defaultMapLongitudeSetting: this.extractSingleSetting('default_map_longitude_setting', fetchedSettings),
            defaultMapZoom: this.extractSingleSetting('default_map_zoom', fetchedSettings),
            defaultProjectAuthor: this.extractSingleSetting('default_project_author', fetchedSettings),
            defaultProjectValidityYear: "2020"
        };
    }

    extractSingleSetting(settingKey, fetchedSettings) {
        return fetchedSettings.filter(fetchedSetting => (fetchedSetting.setting.key === settingKey))[0].setting.value
    }

    areAllKeysPresentInResponse(fetchedSettings) {
        var areAllKeysPresent = true;
        var fetchedSettingsKeys = fetchedSettings.map(fetchedSetting => {
            return fetchedSetting.setting.key;
        });
        this.settingsKeys.forEach(settingKey => {
            if (!fetchedSettingsKeys.includes(settingKey)) {
                areAllKeysPresent = false;
            }
        });
        return areAllKeysPresent;
    }

    updateLocalStorage(fetchedSettings) {
        localStorage.setItem('aws_host_setting', this.extractSingleSetting('aws_host_setting', fetchedSettings));
        localStorage.setItem('map_box_api_key_setting', this.extractSingleSetting('map_box_api_key_setting', fetchedSettings));
        localStorage.setItem('default_map_latitude_setting', this.extractSingleSetting('default_map_latitude_setting', fetchedSettings));
        localStorage.setItem('default_map_longitude_setting', this.extractSingleSetting('default_map_longitude_setting', fetchedSettings));
        localStorage.setItem('default_map_zoom', this.extractSingleSetting('default_map_zoom', fetchedSettings));
        localStorage.setItem('default_project_author', this.extractSingleSetting('default_project_author', fetchedSettings));
    }
}